import React, { Component } from 'react';
// import MediaQuery from 'react-responsive'
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";

import Home from './home';
import About from './about';
import Sight from './sight';
import RouterPage from './routerPage';
import Contact from './contact';
import ServicePage from './servicePage';
import Menu from './components/menu';
import Header from './components/header';
import {GET,POST,ApiList} from './utils/network';
import {WXSHARE} from './utils/wxtools';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      a:1,
      isShow: false,
    }
  }

  componentDidMount(){
    this.testGet();
    this.testPost();
    this.wx_Share();
}


testGet = async ()=>{
    try {
      let res  = await GET(ApiList.GetBannerList,{a:3,b:4});
      console.log(res);
    } catch (e) {
      console.log(e);
    }
}

testPost = async ()=>{
    try {
      let res  = await POST(ApiList.GetSomething,{a:3,b:4});
      console.log(res);
    } catch (e) {
      console.log(e);
    }
}

wx_Share=()=>{
  WXSHARE(
    {
      title:'SANGONSEK圣安石',
      desc: '點亮你的旅途。',
      link: 'https://www.sangonsek.com',
      imgUrl: 'http://mapp.alicdn.com/1592732461618QaPrTVLO4pJSFxe.png',
    }
  );
}

handleMenu() {
  this.setState({
      isShow: !this.state.isShow
  }, () => {
    if(this.state.isShow){
      document.body.style.overflow = 'hidden';
    }else{
      document.body.style.overflow = 'auto';
    }
  })
}
handleClick(){
  this.setState({
      isShow: false
  }, () => {
    document.body.style.overflow = 'auto';
  })
}


  render() {
    const { isShow} =this.state;
    return (
      <Router>
      <div>
        <Switch>
          <div>
             <Header isShow={isShow} handleMenu={() => this.handleMenu()} handleLogo={() => this.handleClick()}/>
            <Menu isShow={isShow} handleClick={() => this.handleClick()}></Menu>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/sight">
              <Sight />
            </Route>
            <Route exact path="/routerPage">
              <RouterPage />
            </Route>
            <Route exact path="/servicePage">
              <ServicePage />
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>

            <Route exact path="/">
              <Home />
            </Route>
          </div>
        </Switch>

      </div>
    </Router>
    );
  }
}
