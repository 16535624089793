import React, { Component } from 'react';
import './style.scss';

import Header from '../components/header';

export default class Contact extends Component{
    constructor(){
        super()
    }

    render(){
        return(
            <main>
                <div className="about">
                    <div className="banner" style={{height: "713px"}}>
                        <div className="banner_bg">
                            <img src={require("../static/img/banner1.png")}/>
                        </div>
                        <div className="banner_body">
                            <div className="header">
                                <h1>Contact</h1>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}