import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import scrollToComponent from 'react-scroll-to-component';
import './style.scss';
import san from "../static/img/san.png";
import light from '../static/img/light.png'

import three from '../static/img/three.png'
import Footer from '../components/footer';

import SwiperComponent from '../components/swiperComponent';
import Service from '../components/service';




export default class Home extends Component{
    constructor(){
        super()
        this.state={
            secondIs: false,
            threeLeftIs: false,
            isMenu: false,
            isShow: false,
            animateData: [
                {name: 'secondId', isShow: false},
                {name: 'threeLeftId', isShow: false},
                {name: 'threeRightId', isShow: false},
                {name: 'headerId', isShow: false},
                {name: 'titleId', isShow: false},
                {name: 'bodyId', isShow: false},
                {name: 'bodyButtonId', isShow: false},
                {name: 'careerLeftId', isShow: false},
                {name: 'careerrightId', isShow: false},
                {name: 'careerHeaderId', isShow: false},
                {name: 'careerTitleId', isShow: false},
                {name: 'careerBodyId', isShow: false},
                {name: 'careerButId', isShow: false},


            ]
        }
    }
    componentDidMount() {

        window.addEventListener("scroll", () => this.animate())

    }
    animate() {
        const {animateData} = this.state;
        let windowH = Number(window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
        for(let i=0; i< animateData.length; i++){
            if(this[animateData[i].name] && this.clientRect(this[animateData[i].name]) < windowH){
                animateData[i].isShow= true;
            }
        }

        this.setState({
            animateData:animateData
        })
    }
    clientRect(e){
       return e.getBoundingClientRect().top;
    }

    scrollToAnchor = (ref) => {
        scrollToComponent(ref, { offset: 0, align: 'top', duration: 500});
    }

    componentWillUnmount() {
        window.removeEventListener("scroll",() => this.animate())
    }

    render(){
        const { isMenu,isShow,animateData} = this.state;
        return(
            <div className="home">
               <main>
                <section className="homeBanner" ref={(ref) => this.bannerId=ref}>
                    <SwiperComponent></SwiperComponent>
                    <a  className="scroll-arrow">
                        <div onClick={()=>{this.scrollToAnchor(this.secondId)}} className="arrow"></div>
                    </a>
                </section>
                <section id="slogan" ref={(ref) => this.secondId = ref} className={`second js-viewport  ${animateData[0].isShow ? 'viewed' : ''}`}>
                    <div className="slogan_bg">
                        <img src="http://mapp.alicdn.com/15914291649535HfkYtu1r9vdyTZ.png" />
                    </div>
                    <div className="container">
                        <div className="secondContent">
                            <span className="secondName">圣 安 石</span>
                            <div className="lightBox"><img className="light" src={light}/></div>
                            <p className="lightTitle">“点亮你的旅途”</p>
                            <p className="lightInfo">福建圣安石生态农业旅游有限公司（台港澳合资）为香港信德华发展公司旗下子公司，香港信德华发展公司是一家现代化综合型企业，企业旗下涵盖酒店、房地产、餐饮、微生物环保饲料、畜牧养殖、木业与休闲娱乐会所多项业务，多年来，企业凭借多元化发展道路，持续稳步前进，积极为广大客户提供完善服务，不断锐意创新，为社会创造卓越价值。
                            </p>
                            <Link className="button_view" to="/">
                                <div className="button a-button white expanded">
                                    <span>了解更多</span>
                                </div>

                            </Link>
                        </div>
                    </div>
                </section>

                <section className="three" >
                    <div className="threeContent about_row js-viewport-children">
                        <div className={`about_left ${animateData[1].isShow ? 'viewed': ''} `} ref={(ref) => this.threeLeftId = ref}>
                            <div  className="about_img">
                                <figure className="a-image">
                                    <img className="a_img" src="http://mapp.alicdn.com/1591429300794ENVSBl5hUifbZbk.png"/>
                                </figure>
                            </div>

                        </div>
                        <div className={`about_right ${animateData[2].isShow ? 'viewed': ''}`} ref={(ref) => this.threeRightId = ref}>
                            <div className="about_body ">
                                <div className="threeRight container-narrow js-viewport-children">
                                    <div className={ animateData[3].isShow ? "viewed" : ''} ref={(ref) => this.headerId = ref}>
                                        <span className="sangonsek">—————— SANGONSEK </span>
                                        <div ><h1>圣安石</h1></div>
                                    </div>

                                    <div className={ animateData[4].isShow ? "viewed" : ''}  ref={(ref) => this.titleId = ref}><h3>体验自然 • 远离喧嚣 </h3></div>
                                    <div className={ animateData[5].isShow ? "viewed" : ''}  ref={(ref) => this.bodyId = ref} > <p className="threeInfo">圣安石农旅隐蔽于乡村间，处于福建南部，一路随着蜿蜒的山路，遇见这片天堂。旅游区域内有攀登者最喜爱的探险之旅；母亲河上的慢漂成为你欣赏迤逦风光的惬意时光；生态林里散发出花朵与树木的芬芳，给你一趟心旷神怡的灵修与冥想。</p></div>
                                    <Link className={`button_view ${ animateData[6].isShow ? "viewed" : ''} `} to="/" ref={(ref) => this.bodyButtonId = ref}>
                                        <div className="button a-button white expanded">
                                            <span>了解更多</span>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>


                    </div>

                </section>
                <Service></Service>
                <section className="career ">
                    <div className="career_row js-viewport-children">
                        <div className={`career_right ${ animateData[7].isShow ? 'viewed': ''}`} ref={(ref) => this.careerLeftId = ref}>
                            <div className="career_image">
                                <figure className="a-image">
                                    <img className="a_img" src="http://mapp.alicdn.com/1591429061815ysBQwM2XH4hXhjy.png"/>
                                </figure>
                            </div>

                        </div>
                        <div className={`career_left ${ animateData[8].isShow ? 'viewed': ''}`} ref={(ref) => this.careerrightId = ref}>
                            <div className="career_body">
                                <div className="container-narrow js-viewport-children">
                                    <h1 className={ animateData[9].isShow ? 'viewed': ''} ref={(ref) => this.careerHeaderId = ref}>Pick your <br/> own <br/>"JOURNEY"</h1>
                                    <p className={`fontSecond ${ animateData[10].isShow ? 'viewed': ''}`} ref={(ref) => this.careerTitleId = ref}>选 择 圣 安 石，为 你 的 旅 途 提 供 一 个 “完 美”。</p>
                                    <p className={`fontFive text ${ animateData[11].isShow ? 'viewed': ''}`} ref={(ref) => this.careerBodyId = ref}>比跟团游愉悦自在，比自由行安心省力。<br/>完美旅途一价全包，专属旅行顾问全场保障。<br/>精致旅游线路，带你体验最纯正的风情。</p>
                                    <Link className={`button_view ${ animateData[12].isShow ? 'viewed': ''}`} to="/" ref={(ref) => this.careerButId = ref}>
                                        <div className="button a-button white expanded">
                                            <span>了解更多</span>
                                        </div>
                                    </Link>

                                </div>

                            </div>

                        </div>
                    </div>


                </section>
                </main>

                <Footer></Footer>
            </div>
        )
    }
}
