import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


const fontsize = ()=> {
  let html = window.document.documentElement;
  let wW = html.clientWidth;// 窗口宽度
  let designSize=750;
  if(wW> 750){
    designSize = 1920; 
  }else{
     designSize = 750; 
  }
    // 设计图尺寸
    let rem = wW * 100 / designSize; 
  
    document.documentElement.style.fontSize = rem + 'px';
}
window.onload = () => fontsize()
window.onresize = () => fontsize()
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
