import React, { Component } from 'react';
import './style.scss';
import logo from '../../static/img/logo.png';
import {
    Link,
  } from "react-router-dom";

import qq from '../../static/img/qq.png';
import weibo from '../../static/img/weibo.png';
import weixin from '../../static/img/weixin.png';
import ins from '../../static/img/ins.png';
  

export default class Menu extends Component{
    constructor(){
        super()
        this.state={
        }
    }
    componentDidMount() {
    }
  
    handleLink() {
        this.props.handleClick()
    }

    render(){
        
        const { isShow } = this.props;
        
        return(
            <div className={`js-menu menu init ${isShow ? 'show': '' } `} >
                {
                    console.log("show", isShow)
                }
                <div className="menu_bg"></div>
                <div className="menu_left" >
                    <div className="menu_inner">
                        <Link to="/" className="menu_item" onClick={()=> this.handleLink()}>
                            <div className="item-body">
                                <p className="label">ABOUT</p>
                                <p className="label_text">公司简介</p>
                            </div>
                        </Link>
                        <Link to="/" className="menu_item" onClick={()=> this.handleLink()}>
                            <div className="item-body">
                                <p className="label">SIGHT</p>
                                <p className="label_text">旅游景点</p>
                            </div>
                        </Link>
                        <Link to="/" className="menu_item" onClick={()=> this.handleLink()}>
                            <div className="item-body">
                                <p className="label">ROUTE</p>
                                <p className="label_text">旅游路线</p>
                            </div>
                        </Link>
                        <Link to="/" className="menu_item" onClick={()=> this.handleLink()}>
                            <div className="item-body">
                                <p className="label">SERVICE</p>
                                <p className="label_text">销售网络</p>
                            </div>
                        </Link>
                        <Link to="/" className="menu_item" onClick={()=> this.handleLink()}>
                            <div className="item-body">
                                <p className="label">CONTACT</p>
                                <p className="label_text">联系我们</p>
                            </div>
                        </Link>
                        <div  className="menu-button">
                            <div  className="menu_links">
                                <Link to="/" className="label_link" onClick={()=> this.handleLink()}><p>高德地图 AMAP > </p></Link> 
                                <Link to="/" className="label_link" onClick={()=> this.handleLink()}><p>百度地图 BAIDUMAP > </p></Link>
                            </div>
                            <div className="menu_shared">
                                    <Link to="/" className="" onClick={()=> this.handleLink()}>
                                        <img  src="http://mapp.alicdn.com/1591429367937vS1XUWqiU18tWBG.png" />
                                    </Link>
                                    <Link to="/"  className="" onClick={()=> this.handleLink()}>
                                        <img src="http://mapp.alicdn.com/1591429381537vegjW1VHejeowsO.png" />
                                    </Link>
                                    <Link to="/" onClick={()=> this.handleLink()}>
                                    <img className="/" src="http://mapp.alicdn.com/1591429428697kW7FjJlbTxB4L3C.png" />
                                    </Link>
                                    <Link to="/" onClick={()=> this.handleLink()}>
                                        <img className="" src="http://mapp.alicdn.com/1591429213454zpOdOhlGg71RzrI.png" />
                                    </Link>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        )
    }
}