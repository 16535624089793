import React, { Component } from 'react';
import './style.scss';
import logo from '../../static/img/logo.png';
import {
    Link,
  } from "react-router-dom";

export default class Header extends Component{
    constructor(props){
        super(props)
        this.state={
            isTop: false,
        }
    }
    componentDidMount() {
        window.addEventListener("scroll", (e) => this.handleScroll(e))
    }
    handleScroll(e) {
     let scroll = (e.srcElement ? e.srcElement.documentElement.scrollTop : false)  || window.pageYOffset || (e.srcElement ? e.srcElement.body.scrollTop : 0);
	       
    
     if(scroll> 800) {
        this.setState({ isTop: true})
     }else {
        this.setState({ isTop: false})
     }
    }
  
    render(){
        const { isTop } = this.state;
        const { isShow } = this.props;
        console.log('open', this.props.isShow)
        return(
            <header className="header">
                <Link to="/" onClick={() => this.props.handleLogo()}>
                    <div className="headerLeft">
                        <img src="http://mapp.alicdn.com/1591430232492svaqQOponVAubQc.png"/>
                    </div>
                </Link>
               
                    <a  className={`hamburger-button ${isTop ? 'scrolled' : ''}  ${ isShow ? "open": ''}` } onClick={() => this.props.handleMenu()}>
                        <div className="inner">
                            <div className="hamburger">
                                <div className="hamburger_close">
                                    <span className="bar"></span>
                                    <span className="bar"></span>
                                </div> 
                                <div className="hamburger_open">
                                    <span className="bar"></span>
                                    <span className="bar"></span>
                                    <span className="bar"></span>
                                </div>
                            </div>
                        </div>
                      
                    </a>
                    <div className={` menu-header headerRight ${isTop ?  "scrolled" : ''}`}>
                        <div className="header_inner">
                            <Link className="header_item "  to="/">
                                <div className="item_body">
                                    <p className="">公司简介</p>
                                </div>
                                
                            </Link>
                            <a className="header_item" href="/">
                                <div className="item_body">
                                    <p>旅游景点</p>
                                </div>
                            </a>
                            <a className="header_item" href="/">
                                <div className="item_body">
                                    <p>旅游线路</p>
                                </div>
                            </a>
                            <a className="header_item" href="/">
                                <div className="item_body">
                                    <p>销售网络</p>
                                </div>
                            </a>
                            <a className="header_item" href="/">
                                <div className="item_body">
                                    <p>联系我们</p>
                                </div>
                            </a>
                        </div>
                        
                    </div> 
            </header>
        )
    }
}