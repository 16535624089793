import React, { Component } from 'react';
import './style.scss';

import Header from '../components/header';

export default class About extends Component{
    constructor(){
        super()
    }

    render(){
        return(
            <main>
                <div className="about">
                    <div className="banner" style={{height: "713px"}}>
                        <div className="banner_bg">
                            <img src={require("../static/img/banner1.png")}/>
                        </div>
                        <div className="banner_body">
                            <div className="header">
                                <h1>ABOUT US </h1>
                            </div>
                           
                            
                        </div>
                    </div>
                    <div className="aboutContain">
                        <p className="title">福建圣安石生态农业旅游有限公司（台港澳合资）为香港信德华发展公司旗下子公司，香港信德华发展公司是一家现代化综合型企业，企业旗下涵盖酒店、房地产、餐饮、微生物环保饲料、畜牧养殖、木业与休闲娱乐会所多项业务，多年来，企业凭借多元化发展道路，持续稳步前进，积极为广大客户提供完善服务，不断锐意创新，为社会创造卓越价值。</p>
                        <h2 className="text">企业精神：</h2>
                        <p className="text">相信、希望、热忱、积极、拼搏、创新。</p>
                        <h2 className="text">企业文化：</h2>
                        <p className="text">以诚待人，誉从信至、以人为本，尽善尽美、以心为鉴，专业进取。</p>
                        <h2 className="text">企业理念：</h2>
                        <p className="text">永不止步，超越自我、敢为人先，追求卓越、资源整合，服务社会。</p>
                        <h2 className="text">服务理念：</h2>
                        <p className="text">大局意识，服务精神，追求极致，永不停歇。</p>
                        <h2 className="text">企业愿景：</h2>
                        <p className="text">
                            实现产业报国，打造值得信赖，备受青睐的企业，铸就中国民族企业骄傲，让中国更受尊敬。
                            用心服务社会，提供精益服务，打造便捷且舒适，理想化的休闲乐园，成就千万美满家庭。
                            热心服务大众，人文温暖人心，创造温馨服务，让企业充满温暖，让幸福渗透于每个人心中。
                        </p>
                    </div>
                </div>
            </main>
           
        )
    }
}