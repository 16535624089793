var {POST,ApiList} = require('./network')

function WXSHARE (data,success,error) {
    var url=window.location.href.split('#')[0];
    POST(ApiList.WXAuth,{url:url}).then((re)=>{
      if(!re){return;};
      window.wx.config({
           debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
           appId: re.data.appid, // 必填，公众号的唯一标识
           timestamp: re.data.timestamp.toString(), // 必填，生成签名的时间戳
           nonceStr: re.data.nonceStr.toString(), // 必填，生成签名的随机串
           signature: re.data.signature.toString(),// 必填，签名，见附录1
           jsApiList: [
             'onMenuShareTimeline',
             'onMenuShareAppMessage',
           ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
       });

    });

    window.wx.ready(()=>{
      window.wx.onMenuShareAppMessage({
                title: data.title,
                desc: data.desc,
                link: data.link,
                imgUrl: data.imgUrl,
                dataUrl: '',
                success: ()=>{success && success();},
                cancel: ()=>{alert('分享取消');}
            });
      window.wx.onMenuShareTimeline({
                title: data.title,
                link: data.link,
                imgUrl: data.imgUrl,
                success: ()=>{success && success();},
                cancel: ()=>{alert('分享取消');}
            });

    });


    window.wx.error((res)=>{
          error && error();
     });

  }


  module.exports = {
    WXSHARE
  };
