import React, { Component } from 'react';
import './style.scss';
import {
    Link,
  } from "react-router-dom";


export default class Footer extends Component{
    constructor(){
        super()
    }

    render(){
        return(
            <footer className="footer">
            <div className="footerBg"></div>
            <div className="footer_inner">
                <div className="footer_above">
                    <div className="footer_logo">
                        <Link to="/" className=""><img src="http://mapp.alicdn.com/1591430633744RhnsTLFvV0J4nwd.png"/></Link>
                    </div>
                    <div className="footer_link">
                       <Link to="/" className=""><p>企业简介</p></Link>
                       <Link to="/" className=""><p>主题景点</p></Link>
                       <Link to="/" className=""><p>旅游路线</p></Link>
                       <Link to="/" className=""><p>销售网络</p></Link>
                       <Link to="/" className="last_link">联系我们</Link>
                    </div>
                </div>
                <div className="footer_below">
                    <div className="footer_below_left">
                        <p className="footer_name">圣安石生态农业旅游有限公司</p>
                        <div className="footer_info">
                             &nbsp;<br/>
                            &nbsp;<br/>
                            sangonsek@163.com
                        </div>
                    </div>
                    <div className="footer_below_right">
                        <div className="footer_shared">
                            <Link src="/">
                                <img className="" src="http://mapp.alicdn.com/1591429367937vS1XUWqiU18tWBG.png" />
                            </Link>
                            <Link src="/">
                                <img className="" src="http://mapp.alicdn.com/1591429381537vegjW1VHejeowsO.png" />

                            </Link>
                            <Link src="/">
                            <img className="" src="http://mapp.alicdn.com/1591429428697kW7FjJlbTxB4L3C.png" />
                            </Link>
                            <Link src="/">
                                <img className="" src="http://mapp.alicdn.com/1591429213454zpOdOhlGg71RzrI.png" />
                            </Link>
                        </div>
                        <div className="footer_map map">
                            <Link src="">高德地图  AMAP ></Link>
                            <Link src="">百度地图  BAIDUMAP ></Link>
                        </div>
                        <div className="footer_code">
                            <img src="http://mapp.alicdn.com/1591429318718H2YCzjCg4gMQAZ4.png"/>
                            <img src="http://mapp.alicdn.com/1591429318718H2YCzjCg4gMQAZ4.png"/>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        )
    }
}
