import React, { Component } from "react";
import "./style.scss";
import {
    Link,
  } from "react-router-dom";
export default class Service extends Component {
    constructor(){
        super()
        this.state={
           IDData: ["item1","header1","title1","subTitle1","body1",
           "item2","header2","title2","subTitle2","body2",
           "item3","header3","title3","subTitle3","body3"]
        }
    }
    componentDidMount(){
        window.addEventListener("scroll", (e) => this.animate(e))
    }
    animate(e) {
        const { IDData } = this.state;
        let windowH = Number(window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
        for(let i=0; i< IDData.length; i++){
            if(this[IDData[i]] && this.clientRect(this[IDData[i]]) < windowH){
               this.viewed(this[IDData[i]])
            }
        }
    }
    componentWillUnmount() {
        window.removeEventListener("scroll",(e) => this.animate(e))
    }
    clientRect(e){
        return e.getBoundingClientRect().top;
     }
     viewed(e){
        var className = e.getAttribute('class');
        if(!className){
            e.setAttribute('class',"viewed")
        }else if(!className.includes('viewed')){
            className = className.concat(" viewed")//添加动画
            e.setAttribute('class',className)
        }
     }
    render() {
        return (
            <section className="four service_row js-viewport-children">

            <div  className=" service_item fourbox " ref={(ref) => this.item1= ref}>
                <Link  className="image-link" href="/">
                    <div className="link_bg"></div>
                    <div className="link_img">
                        <figure className="a-image">
                        <img src="http://mapp.alicdn.com/1591429127106wAqqVK0R2VliaN9.png"></img>
                        </figure>
                    </div>
                    <div className="link_body">
                        <div className="link_body_inner js-viewport-children">
                            <div ref={(ref) => this.header1= ref}><p className="english">MIGRATORY BIRD FOREST </p></div>
                            <div ref={(ref) => this.title1= ref}><h1>候鸟森林 • 林间银铃</h1></div>
                            <div ref={(ref) => this.body1= ref}><p className="fourInfo"> 约54亩的杉树与松树林里数百只白鹭栖息于此，清晨鹭鸣呖呖啾啾，和平鸽扑棱呼啦，神秘的多种鸟类引领你靠近“原森态”......</p></div>
                        </div>
                    </div>
                </Link>


            </div>
            <div className=" service_item fourbox "ref={(ref) => this.item2= ref}>
                <Link  className="image-link" href="/">
                    <div className="link_bg"></div>
                    <div className="link_img">
                        <figure className="a-image">
                        <img src="http://mapp.alicdn.com/1591429274108k0AJdv0HiMYNpF8.png"></img>
                        </figure>
                    </div>
                    <div className="link_body">
                        <div className="link_body_inner js-viewport-children">
                            <div ref={(ref) => this.header2= ref}><p className="english">COASTAL BEACH </p></div>
                            <div  ref={(ref) => this.title2= ref}><h1>沿岸沙滩 • 欢愉嬉闹</h1></div>
                            <div  ref={(ref) => this.body2= ref}><p className="fourInfo">人造沙滩与河湾一片相连，阳光沙滩加一杯mojito，晒一场日光浴，美黑达人的理想胜地。孩童在浅滩上建筑城堡，俊男美女打着沙滩排球，非海胜似海。</p></div>
                        </div>

                    </div>
                </Link>
            </div>
            <div className="service_item fourbox " ref={(ref) => this.item3= ref}>
                <Link  className="image-link" href="/">
                    <div className="link_bg"></div>
                        <div className="link_img">
                            <figure className="a-image">
                            <img src="http://mapp.alicdn.com/159142928679140gatQYYzgv2Sfl.png"></img>
                            </figure>
                    </div>
                    <div className="link_body">
                        <div className="link_body_inner js-viewport-children">
                            <div ref={(ref) => this.header3= ref}><p className="english">FOLK FARMING </p></div>
                            <div  ref={(ref) => this.title3= ref}><h1>民俗农作 • 生态穿越</h1></div>
                            <div ref={(ref) => this.body3= ref}><p className="fourInfo">提供城市现代人一种回归自然从而获得身心放松的休闲方式，体验原生态的田园风光，远离烦扰，舒缓现代生活工作中的身心压力。 </p></div>
                        </div>
                    </div>
                </Link>
            </div>
        </section>
        )
    }
}
