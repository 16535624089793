import React, { Component } from "react";
import "./style.scss";
import Swiper from 'swiper';

import 'swiper/css/swiper.min.css'

export default class SwiperComponent extends Component {
    constructor(){
        super()
        this.state={
            isTop: false,
            isShow: false
        }
    }
    componentDidMount(){
        var mySwiper = new Swiper(this.swiperID, {
                slidesPerView : 1,
                loop: true,
                autoplay: true
            })
        var timeout = setTimeout(() =>{
                this.setState({
                    isShow: true
                })
            },100)
    }
    
    render() {
        const { isShow } = this.state;
        return (
            <div ref={(ref) => this.swiperID = ref  } className={ isShow ? "swiper-container show" : "swiper-container"}>
                <div className="swiper-wrapper">
                    <div className="swiper-slide">
                        <img className=" banner" src={require("../../static/img/banner1.png")}/>
                        <img className=" mobileBanner" src="http://mapp.alicdn.com/1591429760632Ww8xdOsEY0kSpZm.png"/>
                        <div className="bannerContent">
                            <img className="san" src="http://mapp.alicdn.com/1591429440779vJIcTRrWRS582YJ.png"/>
                            <div className="lightBox"><img className="light" src="http://mapp.alicdn.com/15914294564197DOCM7knX3KUZEZ.png"/></div>
                            <div className="namebox">
                                <span className="homeName">圣 安 石 </span>
                                <span className="homeright"> 漂 流 探 险 X 挑 战 自 己 </span>
                            </div>
                        </div> 
                    </div>
                    <div className="swiper-slide">
                        <img className="banner" src={require("../../static/img/banner2.png")}/>
                        <img className=" mobileBanner" src="http://mapp.alicdn.com/159142924391002noDEmRAXzMBSi.png"/>
                        <div className="bannerContent">
                            <img className="san" src="http://mapp.alicdn.com/1591429440779vJIcTRrWRS582YJ.png"/>
                            <div className="lightBox"><img className="light" src="http://mapp.alicdn.com/15914294564197DOCM7knX3KUZEZ.png"/></div>
                            <div className="namebox">
                                <span className="homeName">圣安石</span>
                                <span className="homeright">房 车 露 营 X 乐 享 生 活</span>
                            </div>
                        </div> 
                    </div>
                    <div className="swiper-slide">
                        <img className="banner" src={require("../../static/img/banner3.png")}/>
                        <img className=" mobileBanner" src="http://mapp.alicdn.com/1591429259375UST57gpYJE3wHIz.png"/>
                        <div className="bannerContent">
                            <img className="san" src="http://mapp.alicdn.com/1591429440779vJIcTRrWRS582YJ.png"/>
                            <div className="lightBox"><img className="light" src="http://mapp.alicdn.com/15914294564197DOCM7knX3KUZEZ.png"/></div>
                            <div className="namebox">
                                <span className="homeName">圣安石</span>
                                <span className="homeright">乐享美食 X 齐聚于此</span>
                            </div>
                        </div> 
                        
                    </div>
                  
                </div>
                <div className="kv-copy">
                    <h1>圣安石 SANGONSEK</h1>
                    <img src="http://mapp.alicdn.com/1591429410936KwUJwMNslVB2yLC.png"/>
                </div>
               
                
            </div>
        )
    }
}