var BaseUrl = 'https://api.sangonsek.com';

var ApiList = {
  WXAuth:BaseUrl + '/wx/auth',
  GetBannerList:BaseUrl + '/ui/some',
  GetSomething:BaseUrl + '/ui/nice'
};

function GET(url,params={}) {
  var fetchUrl = url+'?'+parseParams(params);
  console.log(url);
   var request = {
       method: 'GET',
       credentials: "include",
       headers: {
           'Content-Type': 'application/x-www-form-urlencoded'
       }
   }
   return fetch(fetchUrl,request)
         .then((response) => response.json())
         .catch((error)=>{
         })
}

function POST(url,params={}){
  var fetchUrl = url;
    var request = {
        method: 'POST',
        credentials: "include",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json"
        }
    }
    return fetch(fetchUrl,request)
          .then((response) => response.json())
          .catch((error)=>{})
}

function parseParams(params) {
    var tempArr = [];
    for (var i in params) {
        var key = encodeURIComponent(i);
        var value = encodeURIComponent(params[i]);
        tempArr.push(key + '=' + value);
    }
    return tempArr.join('&');
}


module.exports = {
  ApiList,
  GET,
  POST
};
